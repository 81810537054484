import React,{Component} from 'react'
import HttpHelper from "./HttpHelper"
import WxApi from "./wxapi";
import Config from "../config"
import { Toast,Flex,Modal} from 'antd-mobile'

const isMobile = WxApi.isMobile()
const isWinxin = WxApi.isWeixn()
export default class wx{
    
    // 页面跳转
    static jumpNewPath(path){ //调用这个方法的时候注意使用call方法把this绑定一下
        if(isWinxin || isMobile){
            this.props.history.push(path)
        }else{ //浏览器中新开窗口
            // path = '/test'+path
            window.open(path)
        }
    }
    // type 上传图片 0拍照或相册  1 仅拍照 2仅拍照带水印 uploadType 上传图片类型，部分地方要区分，比如修改头像
    static uploadImage(type=0,uploadType,count=1){
        if(isWinxin && isMobile){ //原生上传
           return wxUpload(type,uploadType,count)
        }else{
            return webUpload(type,uploadType)
        }
    };

    /* 预览图片 string 字符串  
        obj  {current:'',urls:[]}
        isModal: 是否为弹窗展示
    */
     static previewImage(obj,isModal=false){
        if(!obj.current)return;
        if(!obj.urls)obj.urls = obj.current;
        obj.urls = (Array.isArray(obj.urls))?obj.urls:[obj.urls];

        if(isModal){
            showImage(obj);
        }else if(isWinxin && isMobile){ //原生预览
            window.wx.previewImage({
                current: obj.current, 
                urls: obj.urls 
            })
        }else{ //封装预览
            showImage(obj);
        }
    }

    // pc端登录
    static webLogin(){
        if(Config.TOKEN)return       
        if (isMobile && WxApi.isOtherEnvironments()){ //如果是在支付宝内 or 云闪付置浏览器中打开             
            window.location.href = 'https://p.baominggongju.com/login'
        }else if(isMobile || isWinxin){ //手机端，包括微信浏览器和其他浏览器
            WxApi.jumpWxAuth()
        }else{
            toLogin()
        }
    }

    // 支付
    static payfor(payData,pathName,payType){
        if(isWinxin && isMobile && payType !== 'webPay'){ //微信原生支付
            return new Promise((resolve,reject)=>{
                window.wx.chooseWXPay({
                    timestamp: payData.timeStamp,
                    nonceStr: payData.nonceStr,
                    package: payData.package,
                    signType: payData.signType,
                    paySign: payData.paySign,
                    appId: payData.appId,
                    success: function (res) {
                      resolve(res)
                    },
                    fail:function(err){  
                        reject(err)
                    }
                })
            })
        }else{ //web端支付
            return toWebPay(payData,pathName)
        }
    }
}


// web端上传图片
function webUpload (type,uploadType){
    return new Promise((resolve,reject)=>{
        if(type == 2){
            Modal.alert('温馨提示','发起人设置了「仅拍照带水印」，目前仅报名工具小程序支持，请前往小程序去完成吧',[{ text: '我知道了' }])
            reject() 
            return
        }else if(type == 1){
            Modal.alert('温馨提示','发起人设置了仅「拍照」，目前仅手机端支持哦，请前往手机端去完成吧',[{ text: '我知道了' }])
            reject() 
            return
        }
        let inputChoose = document.createElement('input')
        inputChoose.setAttribute('type','file')
        inputChoose.click()
        inputChoose.onchange = function(){
            Toast.loading('上传中...')
            let file = inputChoose.files[0]
            let index = file.name.lastIndexOf('.')
            let fileText = file.name.substring(index,file.name.length)
            let fileName = fileText.toLowerCase() //文件类型
            let fileList = ['.png','.jpg','.jpeg','.gif']
            if(fileList.indexOf(fileName)== -1){
                Toast.hide()
                Toast.info('仅支持jpeg/png/jpg/gif格式的图片')
                return                
             }
        
             let isLt2M = file.size/1024/1024 < 5
             if(!isLt2M){
                Toast.hide()
                Toast.info('上传图片大小不能超过 5M')
                return      
             }

            var formData = new FormData()
            formData.append('file',file)
            formData.append('access_token',Config.TOKEN)
            if(uploadType == 'avatar'){
                formData.append('type','enroll_head')
                formData.append('biz','enroll_upic')                
            }
            HttpHelper.post(Config.api_common_upload,formData).then(response=>{
                let res = response.data
                Toast.hide()
                if(res.sta == 0){
                    res.data.url = res.data.urls[0]
                    resolve(res)
                }else{
                    reject(res)
                    Toast.fail(res.msg)
                }
            })
        }
    })
}

// 微信上传图片  
 function wxUpload(type,uploadType,count) {
    return new Promise((resolve,reject)=>{
        let sourceType = ['album', 'camera']
        if(type == 1){
            sourceType = ['camera']
        }else if(type == 2){
            Modal.alert('温馨提示','发起人设置了「仅拍照带水印」，目前仅报名工具小程序支持，请前往小程序去完成吧',[{ text: '我知道了' }])
            reject()
            return
        }
        window.wx.chooseImage({
            count, // 默认1
            sourceType,
            success: function (res) {
                Toast.loading('上传中...')
                const localIds = res.localIds;
                resolve(uploadImage(localIds,uploadType,count))
            },
            fail:function(err){
                reject(err)
            }
        });
    })
 }

 function uploadImage (localIds,uploadType,count){
    return new Promise(async (resolve,reject)=>{
        let imgList = []
        for (let i = 0; i < localIds.length; i++) {
            let data = await commonUpload(localIds[i],uploadType)
            if(data.sta == 0 && data.data.url){
                if(count == 1){ //上传单图，还是走之前的逻辑
                    Toast.hide()
                    resolve(data)
                }else{//多图
                    imgList.push(data.data.url)    
                }               
            }                  
        }   
        Toast.hide()  
        if(count>1){ //多图           
            resolve({sta:0,urls:imgList})
        }      
    })   
}

function commonUpload(localId,uploadType){
    return new Promise((resolve,reject)=>{
        window.wx.uploadImage({
            localId, // 需要上传的图片的本地ID，由chooseImage接口获得
            isShowProgressTips: 1, // 默认为1，显示进度提示
            success: function (res) {
                let params = {
                    source: Config.bm_domain == true ? 'h5' : '',
                    media_id: res.serverId,
                    access_token: Config.TOKEN
                }
                if(uploadType == 'avatar'){
                    params.type = 'enroll_head'
                }
                HttpHelper.post(Config.api_upload_file,params).then(d=>{
                    resolve(d.data)
                })                
            },
            fail:function(err){
                reject(err)
                Toast.fail(err)
            }
        });  
    })   
}

// 预览图片
function showImage(obj){
    let prvbox = document.getElementsByClassName('container')[0]
    let dialog = `
                    <div class="share-choice-mask" id="maskBtn"></div>
                    <div class="preview-box-container minProgram-panel">
                        <i class="close-btn" id="closeBtn"></i>
                        <div class="img-box">
                            <img src=${obj.current} class="preview-img" />
                        </div>
                    </div>
                `

    let tempNode = document.createElement('div');
    tempNode.setAttribute('class','Detail-minProgram-panel preview-box-dialog')
    tempNode.innerHTML = dialog;
    prvbox.appendChild(tempNode);

    // 关闭弹窗
    let closeBtn = document.getElementById('closeBtn')
    let maskBtn = document.getElementById('maskBtn')
    closeBtn.addEventListener('click',function(){
        const dialogNode = document.getElementsByClassName('preview-box-dialog')[0]
        prvbox.removeChild(dialogNode)
    })
    maskBtn.addEventListener('click',function(){
        const dialogNode = document.getElementsByClassName('preview-box-dialog')[0]
        prvbox.removeChild(dialogNode)
    })
}

// pc端扫码登录
function toLogin(){
    let loginTimer = ''
    let isShow = document.getElementsByClassName('login-box-dialog').length>0
    let prvbox = document.getElementsByClassName('container')[0]
    let timer = 0, code = ''
    if(!isShow){ //避免生成多个弹窗
        let dialog = ` <Flex class="Detail-minProgram-panel">
                <div class="share-choice-mask" id="maskBtn"></div>
                <div class="minProgram-panel">
                    <div class="new-share-minImg">
                        <i class="close-btn" id="closeBtn"></i>
                        <div class="panel-title">微信扫码关注“立吉报名”公众号登录</div>
                        <div class="QRCode-img login-qrcode-box">
                            <img src="" id="scan-login-code"/>
                            <div class="overdue-box">
                                <span>二维码已失效</span>
                                <button class="refresh-btn" id="refreshBtn">刷新二维码</button>
                            </div>
                        </div>
                        <div class="min-tips" id="minTips">
                           
                            <i class="iconfont icon-checkbox-checked icon-radio-img"></i>
                            <span class='text-agreement'>扫码注册后代表您阅读并同意《用户协议》</span>
                        </div>
                    </div>
                </div>
            </Flex>
                `
        let tempNode = document.createElement('div')
        tempNode.setAttribute('class','login-box-dialog')
        tempNode.innerHTML = dialog
        prvbox.appendChild(tempNode)

        HttpHelper.get(Config.api_login_code).then(response=>{
            if(response.data.sta == 0){
                let res = response.data
                code = res.data.code
                document.getElementById('scan-login-code').setAttribute('src',res.data.qrcode)
                getCode()
            }
        })

    }
    function getCode(){
        loginTimer = setInterval(()=>{
            timer ++
            if(timer >= 30){
                document.getElementsByClassName('overdue-box')[0].style.display = 'flex'         
                clearInterval(loginTimer);
            }
            HttpHelper.get(Config.api_pc_login,{params:{code, source: 'h5'}}).then(res=>{
                if(res.data.sta == 0){
                    WxApi.setToken(res.data.data.access_token)
                    clearInterval(loginTimer)
                    const dialogNode = document.getElementsByClassName('login-box-dialog')[0]
                    prvbox.removeChild(dialogNode)
                    // window.location.reload()
                    HttpHelper.get(Config.api_get_userInfo, {params:{access_token: res.data.data.access_token}}).then(d=>{                                    
                        let res = d.data                                    
                        if(res.sta == 0){
                            window._ZHINI({
                                action:"init",  // 初始化开始指令；
                                client_id:res.data.openid   // 用户的唯一标识，由您自行生成，必填且不能为无效字符，否则不生效;
                            });	
                        }
                        window.location.reload()
                    })                                                          
                    // Toast.success('登录成功')                          
                    // setTimeout(()=>{
                    //     window.location.reload()
                    // },1000)
                }
            })
        },2000)
    }

     // 关闭弹窗
     let closeBtn = document.getElementById('closeBtn')
     closeBtn.addEventListener('click',function(){
         const dialogNode = document.getElementsByClassName('login-box-dialog')[0]
         if(dialogNode)prvbox.removeChild(dialogNode)
         clearInterval(loginTimer)
     })

    let tipsBtn = document.getElementById('minTips')
    tipsBtn.addEventListener('click',function(){
        window.open('https://mp.weixin.qq.com/s/iwXotDf6Nwq3NrHjQvxTWQ')
        clearInterval(loginTimer)
    })

    let refreshBtn = document.getElementById('refreshBtn')
    refreshBtn.addEventListener('click', function(){
        timer = 0;
        document.getElementsByClassName('overdue-box')[0].style.display = 'none';
        getCode()
    })
}

// web端调起微信网页支付
function toWebPay(payData,pathName){
    let loginTimer = ''
    let dialog = ` <Flex class="Detail-minProgram-panel">
                        <div class="share-choice-mask" id="maskBtn"></div>
                        <div class="minProgram-panel">
                            <div class="new-share-minImg" style="min-width:350px">
                                <i class="close-btn" id="closeBtn"></i>
                                <div class="panel-title">微信支付</div>
                                <div class="QRCode-img">
                                    <img src="${payData.pay_qrcode}" id="scan-login-code"/>
                                </div>
                                <div class="min-tips">请使用微信扫描上方二维码完成支付</div>
                            </div>
                        </div>
                    </Flex>
                `
    let prvbox = document.getElementsByClassName('container')[0]
    let tempNode = document.createElement('div');
    tempNode.setAttribute('class','login-box-dialog')
    tempNode.innerHTML = dialog;
    prvbox.appendChild(tempNode);

    // 关闭弹窗
    let closeBtn = document.getElementById('closeBtn')
    let maskBtn = document.getElementById('maskBtn')

    closeBtn.addEventListener('click',function(){
        clearInterval(loginTimer)
        clearInterval(dataTime)
        const dialogNode = document.getElementsByClassName('login-box-dialog')[0]
        prvbox.removeChild(dialogNode)
    })
    maskBtn.addEventListener('click',function(){
        clearInterval(loginTimer)
        clearInterval(dataTime)
        const dialogNode = document.getElementsByClassName('login-box-dialog')[0]
        prvbox.removeChild(dialogNode)
    })

    let dataTime = setInterval(()=>{ //五分钟之后，没有任何结果，就关闭支付弹窗
        clearInterval(loginTimer)
        clearInterval(dataTime)
        const dialogNode = document.getElementsByClassName('login-box-dialog')[0]
        prvbox.removeChild(dialogNode)
    },600000)

   return new Promise((resolve,reject)=>{
        if(pathName == 'otherPay'){ //代付
            loginTimer = setInterval(()=>{
                const data = {
                    type:'detail',
                    access_token: Config.TOKEN,
                    out_trade_no:WxApi.getQueryString('no')
                }
                HttpHelper.get(Config.api_apply_replace_info,{params:data}).then(res=>{
                    if(res.data.sta == 0 && res.data.data.pay_status == 3){
                        clearInterval(loginTimer)
                        clearInterval(dataTime)
                        resolve(res.data)
                        closeBtn.click()
                    }
                })                
            },3000)
        }else if(pathName == 'recover'){ //回收站恢复活动            
            loginTimer = setInterval(()=>{
                const data = {                    
                    access_token: Config.TOKEN,
                    out_trade_no:payData.out_trade_no
                }
                HttpHelper.get(Config.api_bak_order,{params:data}).then(res=>{                   
                    if(res.data.sta == 0 && res.data.data.download_url){
                        clearInterval(loginTimer)
                        clearInterval(dataTime)
                        resolve(res.data)
                        closeBtn.click()
                    }
                })                
            },3000)
        }else if(pathName == 'copyEnroll'){ //复制活动
            loginTimer = setInterval(()=>{
                const data = {                    
                    access_token: Config.TOKEN,
                    out_trade_no: payData.out_trade_no
                }
                HttpHelper.get(Config.api_pay_order,{params:data}).then(res=>{                               
                    if(res.data.sta == 0 && res.data.data.pay_status == 3){
                        clearInterval(loginTimer)
                        clearInterval(dataTime)
                        resolve(res.data)
                        closeBtn.click()
                    }
                })   
            }, 3000)
        }else if(pathName == 'vipCard'){ //会员卡    
            loginTimer = setInterval(()=>{
                const data = {                    
                    access_token: Config.TOKEN,
                    out_trade_no: payData.out_trade_no
                }
                HttpHelper.get(Config.api_user_vip_detail,{params:data}).then(res=>{                   
                    if(res.data.sta == 0 && res.data.data.pay_time){
                        clearInterval(loginTimer)
                        clearInterval(dataTime)
                        resolve(res.data.data)
                        closeBtn.click()
                    }
                })                
            },3000)
        } else if(pathName == 'cancelMerchant'){
            loginTimer = setInterval(()=>{
                const data = {                    
                    access_token: Config.TOKEN,
                    out_trade_no: payData.out_trade_no
                }
                HttpHelper.get(Config.api_merchant_apply,{params:data}).then(res=>{                   
                    if(res.data.sta == -1){
                        clearInterval(loginTimer)
                        clearInterval(dataTime)
                        resolve(res.data.data)
                        closeBtn.click()
                    }
                })                
            },3000)
        }else{
            loginTimer = setInterval(()=>{
                HttpHelper.get(Config.api_order_info,{params:{out_trade_no:payData.out_trade_no}}).then(res=>{
                    if(res.data.sta == 0 && res.data.data.status){
                        clearInterval(loginTimer)
                        clearInterval(dataTime)
                        resolve(res.data)
                        closeBtn.click()
                    }
                })
            },2000)   
        }
   })
}