// 消息通知提示弹窗(新弹窗,活动详情专用)
import React, {Component} from 'react';
import { Modal ,Button,Toast} from 'antd-mobile';
import {inject, observer} from "mobx-react/index";
import SetNickname from './SetNickname';
import WxApi from "../common/wxapi";
import Config from "../config/index";

@inject("myEnrollStore", "enrollStore")
@observer
class NesMsgDialog extends Component {
    state = {
        isShow:false,
        noticeNum:0,
        isWatch: 0,
    };

    componentWillReceiveProps(nextProps) {
        this.setState({isWatch: nextProps.sub_user});
	}
    componentDidMount(){
        this.props.onRef(this)          
    }    
    render(){
         const btnCss = {
            width:'100%',
            height:'60px',
            position:'absolute',
            top:'0',
            left:'0px',
            background:"transparent",
            color:"transparent",
            zIndex:999,
            marginBottom:'env(safe-area-inset-bottom)',    
        }
   
        return (
            <div>              
                <SetNickname ref="nickDom" onRef={(ref)=>{ this.childNickname = ref}} onSuccess={()=>{this.subHomePage(1)}}></SetNickname>
                <Modal title="订阅通知" visible={this.state.isShow} animationType="slide-up" popup className="msg-warn-dialog"
                    transparent maskClosable={false} 
                    closable={true}
                    onClose={()=>{this.setDialog('close')}}>
                    <div className="warn-box">  
                        {
                            this.props.sub_user ||!this.props.owner_unionid ? '' : <div className="watch-user-msg flex-between-box">
                            <img className="user-avatar" src={this.props.owner_pic || Config.defaultAvatar}/>
                            <span className="user-name">{this.props.signName}</span>
                            {
                                this.state.isWatch ? <Button className="watch-btn" style={{'backgroundColor':'#D8D8D8'}} onClick={()=>{this.subHomePage(0)}}>已关注</Button>
                                : <Button type="primary" className="watch-btn" onClick={async ()=>{
                                    if(!Config.TOKEN){
                                        React.$wx.webLogin();
                                        return;
                                    }
                                    await this.childNickname.checkUserName(); //判断是否有昵称
                                    this.subHomePage(1);
                                }}>+ 关注</Button>
                            }                   
                        </div> 
                        }                            
                        <div className='msg-box'>                          
                            <div>
                                <h3 className="warn-text">还能接受<span className="notice-num">{this.state.noticeNum}</span>次通知</h3>
                                <p className="cen-text">点击一次即可获得一次通知机会</p>
                            </div>
                            <Button className="add-btn" type="primary" onClick={()=>{
                                if(!Config.TOKEN){
                                    this.setState({isShow:false});
                                    React.$wx.webLogin();
                                }else if(!WxApi.isWeixn()){
                                    this.setState({isShow:false})
                                    Modal.alert('温馨提示','仅微信浏览器支持消息订阅功能，请用微信浏览器打开此链接', [                          
                                        { text: '我知道了'},
                                    ])                       
                                }
                            }}>＋ 订阅</Button>
                            {
                                WxApi.isWeixn() && WxApi.isMobile() && Config.TOKEN ?  <wx-open-subscribe id="subscribe-btn" style={btnCss} template="3VnoJn7ILmAeFH3wxCZyktTIbRNogLt_8ntfmFG5Jl0,l9GmcM9xfZOMWoorpD24LpiGr_oQf6ZGxdN08ooEWR0,E70ynK2JfAiMoRgGExzPfju2VGAXv9uwH-vWYa16yyg">
                                    <script type="text/wxtag-template">
                                        <div style={{color:'transparent'}}>消息订阅</div>
                                    </script>
                                </wx-open-subscribe> : ''      
                            } 
                        </div>            
                    </div>
                </Modal> 
            </div>
        )
    };

    // 增加消息订阅次数 type 1是获取，2是增加
    handleAddNotice(type){
        this.props.myEnrollStore.addSubNotice(type).then(res=>{
            if(res.sta == 0){
                this.setState({
                    noticeNum:res.data.msg_num
                })
            }else if(type !=1){
                Toast.fail(res.msg, 2);
            }
        })
    };

    setDialog(type){
        this.setState({
            isShow:type == 'open'?true:false,
        },()=>{
            if(this.state.isShow && Config.TOKEN){
                this.handleAddNotice(1)   
            }
            if(WxApi.isWeixn() && WxApi.isMobile() && Config.TOKEN){ //监听消息订阅
                let btn = document.getElementById('subscribe-btn');
                if(this.state.isShow && btn){
                    btn.addEventListener('success',  (e) =>{  
                        let isAccept = false                     
                        let data = JSON.parse(e.detail.subscribeDetails)                                
                        for(let a in data){
                            let status = JSON.parse(data[a]).status
                            if(status == 'accept'){
                                isAccept = true
                            }                            
                        }
                        if(isAccept){
                            this.handleAddNotice(2)
                        }else{
                            Modal.alert('温馨提示', '你未开启消息提醒，请去“立吉报名”公众号开启提醒。操作步骤：立吉报名公众号，右上角“...” -> 设置 -> 通知消息管理 -> 接收通知，开启所有通知提醒。', [{ text: '我知道了' }]);
                        }
                    }); 
                    btn.addEventListener('error',  (e) =>{   
                        console.log('订阅失败' + e)
                        Modal.alert('温馨提示', '你未开启消息提醒，请去“立吉报名”公众号开启提醒。操作步骤：立吉报名公众号，右上角“...” -> 设置 -> 通知消息管理 -> 接收通知，开启所有通知提醒。', [{ text: '我知道了' }]);
                    });
                }else if(!this.state.isShow && btn){
                    btn.removeEventListener('success');
                    btn.removeEventListener('error');
                }                        
            } 
        })
    };

    // 关注
    subHomePage(type){
        if(!Config.TOKEN){
            React.$wx.webLogin()
			return
        }
	
		this.props.enrollStore.subscribeHomepage(type, this.props.owner_unionid).then((d) => {
			let label = '取消关注';
			if (type) label = '关注';
			if(d.sta == 0){
				Toast.success(label + '成功', 2);              
				this.setState({	isWatch: type})
			}else if(d.errcode == -7252){
                Modal.alert('温馨提示',d.msg+'。编号：'+d.data.black_no, [
                    { text: '取消', style: 'default'},
                    { text: '复制编号并留言', onPress: () => {	
                        WxApi.copeText(d.data.black_no);
                        Toast.info('复制成功');
                        setTimeout(()=>{
                            this.props.history.replace('/message')
                        },1000);
                    } },
                ])
            }else{
				Toast.fail(label + '失败', 2);
			}
		});
	}
}

export default NesMsgDialog